import React from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { url2section } from 'ion-sections'
import loadable from '@loadable/component'
import NewspaperButton from '../../../assets/images/buttons/capeargus-button.png'
import { DefaultSectionPageSkeletonWrapper, ArticlePageSkeleton } from '../components/Skeletons'

const TitleSectionPage = loadable(() => import('../pages/TitleSectionPage'), {
  fallback: <DefaultSectionPageSkeletonWrapper />
})
const TagPage  = loadable(() => import('../pages/TagPage'))
const Article = loadable(() => import('../pages/Article'), {
  fallback: <ArticlePageSkeleton />
})

const RenderArticle = (props) => {
  const { contentKey } = useParams()
  return <Article {...props} contentKey={contentKey} />
}

const CapeArgus = (props) => (
  <Switch>
    <Route path='/capeargus' exact render={() => (
      
      <TitleSectionPage {...props}
        section='capeargus'
        sectionLabel='Cape Argus'
        relatedSections={[{ id: 'capeargus/news' }, { id: 'capeargus/world' }, { id: 'capeargus/opinion' }, { id: 'capeargus/lifestyle' }, { id: 'capeargus/sport' }, { id: 'capeargus/motoring' }, { id: 'capeargus/cycle-tour' }]}
        title='Cape Argus'
        description='-'
        publication='Cape Argus'
        newspaperTitle='The Cape Argus on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://capeargus.zinioapps.com/shop'
      />
    
    )} />
    <Route path='/capeargus/news' exact render={() => (
      
      <TitleSectionPage {...props}
        section='capeargus/news'
        relatedSections={[{ id: 'capeargus', label: 'Cape Argus' }, { id: 'capeargus/world' }, { id: 'capeargus/opinion' }, { id: 'capeargus/lifestyle' }, { id: 'capeargus/sport' }, { id: 'capeargus/motoring' }, { id: 'capeargus/cycle-tour' }]}
        title='Cape Argus'
        publication='Cape Argus'
        description='-'
        newspaperTitle='The Cape Argus on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://capeargus.zinioapps.com/shop'
      />
    
    )} />
    <Route path='/capeargus/world' exact render={() => (
      
      <TitleSectionPage {...props}
        section='capeargus/world'
        relatedSections={[{ id: 'capeargus', label: 'Cape Argus' }, { id: 'capeargus/news' }, { id: 'capeargus/opinion' }, { id: 'capeargus/lifestyle' }, { id: 'capeargus/sport' }, { id: 'capeargus/motoring' }, { id: 'capeargus/cycle-tour' }]}
        title='Cape Argus'
        publication='Cape Argus'
        description='-'
        newspaperTitle='The Cape Argus on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://capeargus.zinioapps.com/shop'
      />
    
    )} />
    <Route path='/capeargus/opinion' exact render={() => (
      
      <TitleSectionPage {...props}
        section='capeargus/opinion'
        relatedSections={[{ id: 'capeargus', label: 'Cape Argus' }, { id: 'capeargus/news' }, { id: 'capeargus/world' }, { id: 'capeargus/lifestyle' }, { id: 'capeargus/sport' }, { id: 'capeargus/motoring' }, { id: 'capeargus/cycle-tour' }]}
        title='Cape Argus'
        publication='Cape Argus'
        description='-'
        newspaperTitle='The Cape Argus on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://capeargus.zinioapps.com/shop'
      />
    
    )} />
    <Route path='/capeargus/lifestyle' exact render={() => (
      
      <TitleSectionPage {...props}
        section='capeargus/lifestyle'
        relatedSections={[{ id: 'capeargus', label: 'Cape Argus' }, { id: 'capeargus/news' }, { id: 'capeargus/world' }, { id: 'capeargus/opinion' }, { id: 'capeargus/sport' }, { id: 'capeargus/motoring' }, { id: 'capeargus/cycle-tour' }]}
        title='Cape Argus'
        publication='Cape Argus'
        description='-'
        newspaperTitle='The Cape Argus on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://capeargus.zinioapps.com/shop'
      />
    
    )} />
    <Route path='/capeargus/sport' exact render={() => (
      
      <TitleSectionPage {...props}
        section='capeargus/sport'
        relatedSections={[{ id: 'capeargus', label: 'Cape Argus' }, { id: 'capeargus/news' }, { id: 'capeargus/world' }, { id: 'capeargus/opinion' }, { id: 'capeargus/lifestyle' }, { id: 'capeargus/motoring' }, { id: 'capeargus/cycle-tour' }]}
        title='Cape Argus'
        publication='Cape Argus'
        description='-'
        newspaperTitle='The Cape Argus on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://capeargus.zinioapps.com/shop'
      />
    
    )} />
    <Route path='/capeargus/leadsa' exact render={() => (
      
      <TitleSectionPage {...props}
        section='capeargus/leadsa'
        title='Cape Argus'
        publication='Cape Argus'
        description='-'
        newspaperTitle='The Cape Argus on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://capeargus.zinioapps.com/shop'
      />
    
    )} />
    <Route path='/capeargus/motoring' exact render={() => (
      
      <TitleSectionPage {...props}
        section='capeargus/motoring'
        relatedSections={[{ id: 'capeargus', label: 'Cape Argus' }, { id: 'capeargus/news' }, { id: 'capeargus/world' }, { id: 'capeargus/opinion' }, { id: 'capeargus/lifestyle' }, { id: 'capeargus/sport' }, { id: 'capeargus/cycle-tour' }]}
        title='Cape Argus'
        publication='Cape Argus'
        description='-'
        newspaperTitle='The Cape Argus on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://capeargus.zinioapps.com/shop'
      />
    
    )} />
    <Route path='/capeargus/cycle-tour' exact render={() => (
      
      <TitleSectionPage {...props}
        section='capeargus/cycle-tour'
        relatedSections={[{ id: 'capeargus', label: 'Cape Argus' }, { id: 'capeargus/news' }, { id: 'capeargus/world' }, { id: 'capeargus/opinion' }, { id: 'capeargus/lifestyle' }, { id: 'capeargus/sport' }, { id: 'capeargus/motoring' }]}
        title='Cape Argus'
        publication='Cape Argus'
        description='-'
        newspaperTitle='The Cape Argus on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://capeargus.zinioapps.com/shop'
      />
    
    )} />
    <Route path='/capeargus/the-dignity-project' exact render={() => (
      
      <TitleSectionPage {...props}
        section='capeargus/the-dignity-project'
        title='Cape Argus'
        publication='Cape Argus'
        description='The Dignity Project is a 15-part daily series run by the Cape Argus about the homeless people of Cape Town'
        newspaperTitle='The Cape Argus on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://capeargus.zinioapps.com/shop'
      />
    
    )} />
    <Route path={'/capeargus/:section([a-zA-Z0-9-/]+)/:title*([-])*:contentKey([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})'} exact render={() => (
      <RenderArticle {...props} oovvuu='4c0314aa-87fa-4a57-8152-521cc269c23c' />
    )} />
    <Route path={'/capeargus/:title*([-])*:contentKey([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})'} exact render={() => (
      <RenderArticle {...props} oovvuu='4c0314aa-87fa-4a57-8152-521cc269c23c' />
    )} />
    <Route path={'/capeargus/:section([a-zA-Z0-9-/]+)/:title*([-])*:contentKey([0-9]{5,})'} exact component={RenderArticle} />
    <Route path={'/capeargus/:title*([-])*:contentKey([0-9]{5,})'} exact component={RenderArticle} />
    <Route path={'/capeargus/:section([a-zA-Z0-9-/]+)'} exact render={() => (
      <TagPage {...props}
        section={url2section(props.location.pathname)}
        url={props.location.pathname}
      />
    )} />
  </Switch>
)

export default CapeArgus
